<template>
  <div>

    <!-- If admin show eclipse -->
    <p
      class="team-members-functions"
      v-click-outside="onClickOutside"
      @click="openMembersOptions"
      v-if=" currentIsAdmin == '1'"
    >
      <img src="../../../../assets/images/ellipsis-h.png" alt=""/>
    </p>

    <!-- If non-admin show eclipse on others member for direct message-->
    <p
      class="team-members-functions"
      v-click-outside="onClickOutside"
      @click="openMembersOptions"
      v-else-if="currentIsAdmin != '1' && items.id != currentUserId"
    >
      <img src="../../../../assets/images/ellipsis-h.png" alt=""/>
    </p>

    <p
      class="team-members-functions"
      v-click-outside="onClickOutside"
      @click="openMembersOptions"
      v-else-if="currentIsAdmin != '1' && items.id == currentUserId  && items.fanRole != '1' && selectedRoomTeam.teamType == 'sports'"
    >
      <img src="../../../../assets/images/ellipsis-h.png" alt=""/>
    </p>

    <!-- If admin show eclipse -->
    <div class="sub-setting-options" v-if="memberOptions && currentIsAdmin == '1'">
      <ul>
        <li
          v-if="currentIsAdmin == '1' && items.adminRole != '1'"
          @click="makeAdmin(items.username, items.id)"
        >
          Make team admin
        </li>
        <li
          v-if="currentIsAdmin == '1' && items.fanRole != '1'"
          @click="makefan(items.username, items.id)"
        >
          Make team fan
        </li>
        <li
          v-if="currentIsAdmin == '1' && items.adminRole != '0'"
          @click="makeMember(items.username, items.id)"
        >
          Make team member
        </li>
        <li
          v-if="currentIsAdmin == '1' && items.adminRole == '1'"
          @click="removeAdmin(items.username, items.id)"
        >
          Remove as team admin
        </li>
        <li
          v-if="currentIsAdmin == '1' && items.fanRole == '1'"
          @click="removeFan(items.username, items.id)"
        >
          Remove as team fan
        </li>
        <li
          v-if="currentIsAdmin == '1' && items.id != currentUserId"
          @click="removeMembers(items.username, items.id)"
        >
          Remove from team
        </li>
        <li
          v-if="items.id != currentUserId"
          @click="fetchDirectMessageData(items.username, items.c_id)"
        >
          Send direct message
        </li>
        <li
          v-if="currentIsAdmin == '1' && items.fanRole != '1' && selectedRoomTeam.teamType == 'sports' "
          data-toggle="modal"
          data-target="#addUniform_Modal"
          @click="addUniformModal(items.id)"
        >
        Player info
        </li>
      </ul>
    </div>

    <!-- If non-admin and not itself show direct message-->
    <div class="sub-setting-options" v-else-if="memberOptions && currentIsAdmin != '1' && items.id != currentUserId && selectedRoomTeam.teamType == 'sports'">
      <ul>
        <li
          v-if="items.id != currentUserId"
          @click="fetchDirectMessageData(items.username, items.c_id)"
        >
          Send Direct Message
        </li>
        <li
          v-if="currentIsAdmin == '1' && selectedRoomTeam.teamType == 'sports'"
          data-toggle="modal"
          data-target="#addUniform_Modal"
          @click="addUniformModal(items.id)"
        >
        Player info
        </li>
      </ul>
    </div>
    
     <div class="sub-setting-options" v-else-if="memberOptions && currentIsAdmin != '1' && items.id != currentUserId && selectedRoomTeam.teamType != 'sports'">
      <ul>
        <li
          v-if="items.id != currentUserId"
          @click="fetchDirectMessageData(items.username, items.c_id)"
        >
          Send Direct Message
        </li>
      </ul
      >
    </div>

     <div class="sub-setting-options" v-else-if="memberOptions && currentIsAdmin != '1' && items.id == currentUserId && selectedRoomTeam.teamType == 'sports'">
      <ul>
        <li
          data-toggle="modal"
          data-target="#addUniform_Modal"
          @click="addUniformModal(items.id)"
        >
        Player info
        </li>

      </ul>
    </div>

    <div  style="display:none" 
          id="openModalClose"
          data-toggle="modal"
          data-target="#individualModalDirect">

    </div>

    <!-- Create Chat -->
    <DirectMessageModal :type="type" :userId="items.c_id" />
    <!-- Create chat modal -->

    <!-- UpdateUniformID -->
    <AddUniformModal :selectedRoomTeam="selectedRoomTeam"/>
    <!-- UpdateUniformID -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as firestoreService from "../../../../database/firestore";
import $ from "jquery";
import DirectMessageModal from "../../../Teams/ChatRight/ChatSettingComponents/DirectMessageModal.vue";
import AddUniformModal from "../../../Teams/ChatRight/ChatSettingComponents/AddUniformModal";

export default {
  name: "MemberOptions",
  components: {
    DirectMessageModal,
    AddUniformModal
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      memberOptions: false,
      roomId: this.selectedRoomTeam.roomId,
      sentUserName: "",
      sentUserId: "",
      type: "chat",
      Storage_URL: this.$userProfileStorageURL,
      currentUserName: localStorage.getItem("userName"),
      API_URL: this.$userProfileStorageURL,
      foundCurrentUser: [],
      blockUserFlag:0,
    };
  },
  props: {
    items: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentUserId: {
      type: String,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsFan:{
      type: String
    },
  },

  mounted() {
    $(".swal2-confirm").html("Yes");
  },
  methods: {
    openMembersOptions() {
      this.memberOptions = !this.memberOptions;
    },

    onClickOutside() {
      this.memberOptions = false;
    },

    // ---- Remove Members ----------------------------------------------------------------------
    async removeMembers(name, removeUserId) {
      this.$confirm(
        "About to remove " + name + " from the team. Continue ?",
        "Remove Member."
      ).then(() => {
        firestoreService.removeRoomUser(this.roomId, removeUserId);
        
        // Remove from User Role Array
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == removeUserId) {
            this.selectedRoomTeam.userRole.splice(i, 1);
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: this.selectedRoomTeam.userRole,
        });

        // Remove from User Count Array
        const teamSUserCount = this.selectedRoomTeam.userCount;
        var s = 0;
        for (const userCount of teamSUserCount) {
          if (userCount.id == removeUserId) {
            this.selectedRoomTeam.userCount.splice(s, 1);
          }
          s++;
        }
        firestoreService.updateRoom(this.roomId, {
          userCount: this.selectedRoomTeam.userCount,
        });
        
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeStatisticsReload");
        this.$root.$emit("gameTimeTeammatesReload");
        // this.$root.$emit("fetchTeamNicknamesaction");
        this.$root.$emit("fetchTeamNicknames");
        
        if(window.innerWidth < 981){
          this.$router.push({ name: "Teams" });
          // this.$root.$emit("fetchTeamNicknamesaction");
        }
        else{
          this.$root.$emit("fetchTeamNicknamesaction");
        }
      });
    },


    // ---- Make Admin ----------------------------------------------------------------------
    async makeAdmin(name, userID) {
      this.$confirm(
        "About to make " + name + " admin of this team. Continue ?",
        "Make Admin"
      ).then(() => {
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == userID) {
            teamSUserRole[i].admin = "1";
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: teamSUserRole,
        });
        
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");
      });
    },
    // ---- Make Fan ----------------------------------------------------------------------
    async makefan(name, userID) {
      this.$confirm(
        "About to make " + name + " fan of this team. Continue ?",
        "Make Fan"
      ).then(() => {
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == userID) {
            teamSUserRole[i].fan = "1";
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: teamSUserRole,
        });
        
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");
      });
    },


     async removeFan(name, userID) {
      this.$confirm(
        "About to remove " + name + " as fan of this team. Continue ?",
        "Remove Fan"
      ).then(() => {
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == userID) {
            teamSUserRole[i].fan = "0";
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: teamSUserRole,
        });
        
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");
      });
    },

    // ---- Make Member ----------------------------------------------------------------------
    async makeMember(name, userID) {
      const teamSUserRole = this.selectedRoomTeam.userRole;

      // Count the number of admins
      const numberOfAdmins = teamSUserRole.filter(userRole => userRole.admin === "1").length;

      // Check if the current user is the only admin
      const currentUserIsOnlyAdmin = numberOfAdmins === 1 && teamSUserRole.find(userRole => userRole.id === this.currentUserId && userRole.admin === "1");

      if (currentUserIsOnlyAdmin) {
        this.$alert("Make someone admin first");
        return;
      }
      
      this.$confirm(
        "About to make " + name + " normal user of this team. Continue ?",
        "Make Member"
      ).then(() => {
        const teamSUserRole = this.selectedRoomTeam.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == userID) {
            teamSUserRole[i].admin = "0";
            teamSUserRole[i].fan = "0";
          }
          i++;
        }
        firestoreService.updateRoom(this.roomId, {
          userRole: teamSUserRole,
        });
        
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");
      });
    },

    // ---- Remove from Admin ----------------------------------------------------------------------
    // async removeAdmin(name, userID) {
    //   this.$confirm(
    //     "About to remove " + name + " as admin of this team. Continue ?",
    //     "Remove Admin"
    //   ).then(() => {
    //     const teamSUserRole = this.selectedRoomTeam.userRole;
    //     var i = 0;
    //     for (const userRole of teamSUserRole) {
    //       if (userRole.id == userID) {
    //         teamSUserRole[i].admin = "0";
    //       }
    //       i++;
    //     }
    //     firestoreService.updateRoom(this.roomId, {
    //       userRole: teamSUserRole,
    //     });
        
    //     this.$root.$emit("gameTimeScheduledReload");
    //     this.$root.$emit("gameTimeTeammatesReload");
    //     this.$root.$emit("gameTimeStatisticsReload");
    //   });

    // },

    // ---- Remove from Admin ----------------------------------------------------------------------
async removeAdmin(name, userID) {
  const teamSUserRole = this.selectedRoomTeam.userRole;

  // Count the number of admins
  const numberOfAdmins = teamSUserRole.filter(userRole => userRole.admin === "1").length;

  // Check if the current user is the only admin
  const currentUserIsOnlyAdmin = numberOfAdmins === 1 && teamSUserRole.find(userRole => userRole.id === this.currentUserId && userRole.admin === "1");

  if (currentUserIsOnlyAdmin) {
    this.$alert("Make someone admin first");
    return;
  }

  this.$confirm(
    "About to remove " + name + " as admin of this team. Continue ?",
    "Remove Admin"
  ).then(() => {
    var i = 0;
    for (const userRole of teamSUserRole) {
      if (userRole.id == userID) {
        teamSUserRole[i].admin = "0";
      }
      i++;
    }
    firestoreService.updateRoom(this.roomId, {
      userRole: teamSUserRole,
    });
    
    this.$root.$emit("gameTimeScheduledReload");
    this.$root.$emit("gameTimeTeammatesReload");
    this.$root.$emit("gameTimeStatisticsReload");
  });
},


    // ---- Send Message ----------------------------------------------------------------------
    fetchDirectMessageData(name, userID) {
      document.getElementById('openModalClose').click();
      this.$root.$emit("fetchDirectMessageData", name, userID);
    },

    // ---- Send Message ----------------------------------------------------------------------
    addUniformModal(userChatId) {
      this.$root.$emit("fetchUniformData", userChatId);
    },
    }
  }
  
</script>
<style>
.team-members-functions {
  cursor: pointer;
  position: relative;
}
.loaderSign {
  width: 45px !important;
  margin-top: 0px !important;
}
.team-members-functions img {
  cursor: pointer;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.sub-setting-options {
  z-index: 1;
}
.sub-setting-options ul{
  display: block;
}
</style>